@font-face {
  font-family: "ibm_plex_sansbold";
  src: url("ibmplexsans-bold-webfont.woff2") format("woff2"),
    url("ibmplexsans-bold-webfont.woff") format("woff"),
    url("ibmplexsans-bold-webfont.svg#ibm_plex_sansbold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ibm_plex_sanslight";
  src: url("ibmplexsans-light-webfont.woff2") format("woff2"),
    url("ibmplexsans-light-webfont.woff") format("woff"),
    url("ibmplexsans-light-webfont.svg#ibm_plex_sanslight") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ibm_plex_sansmedium";
  src: url("ibmplexsans-medium-webfont.woff2") format("woff2"),
    url("ibmplexsans-medium-webfont.woff") format("woff"),
    url("ibmplexsans-medium-webfont.svg#ibm_plex_sansmedium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ibm_plex_sansregular";
  src: url("ibmplexsans-regular-webfont.woff2") format("woff2"),
    url("ibmplexsans-regular-webfont.woff") format("woff"),
    url("ibmplexsans-regular-webfont.svg#ibm_plex_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ibm_plex_sanssemibold";
  src: url("ibmplexsans-semibold-webfont.woff2") format("woff2"),
    url("ibmplexsans-semibold-webfont.woff") format("woff"),
    url("ibmplexsans-semibold-webfont.svg#ibm_plex_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("https://www.apollo247.com/config/Inter-VariableFont-lite.woff2")
      format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("https://www.apollo247.com/config/Inter-VariableFont-lite.woff2")
      format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("https://www.apollo247.com/config/Inter-VariableFont-lite.woff2")
      format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("https://www.apollo247.com/config/Inter-VariableFont-lite.woff2")
      format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("https://www.apollo247.com/config/Inter-VariableFont-lite.woff2")
      format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
